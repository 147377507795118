import { Col, Container, Row } from "react-bootstrap";
import "./index.scss";

const Home = (): JSX.Element => {
  return (
      <div className="home">
        <div className="main">
          <div className="bg">
            <div className="bg_con"></div>
          </div>
          <div className="phone-logo">
              <img src={require('../../assets/image/logo.png').default} alt="" width='100%'/>
            </div>
          <div className="content">
            
            <div className="middle">
              <Container>
                <Row className=" pb-md-1 px-md-2 img-row" >
                  <Col xs={12} md={12} lg={6} xl={5} className=' d-flex flex-column justify-content-center px-md-0'>
                      <div className="logo">
                        <img src={require('../../assets/image/logo.png').default} alt="" width='100%'/>
                      </div>
                      <h1 className="title text-center text-md-center text-lg-start text-xxl-start">
                      R3AL CAM
                      </h1>
                      <h2 className="subtitle text-center text-md-center text-lg-start text-xxl-start">AR Experience IRL</h2>
                  </Col>
                  <Col xs={0} md={12} lg={6} xl={7} className='px-md-0 px-0 phone-order'>
                    <div className="py-md-4 px-lg-0 py-lg-0 px-0 py-0 pb-3 photo-big pc-img">
                      <div className="cover">
                        <img src={require('../../assets/image/jpg_bg_1.png').default} alt="" width='100%'/>
                      </div>
                    </div>
                    <div className="py-md-4 px-lg-0 py-lg-0 px-0 py-0 pb-1 photo-big phone-img">
                      <div className="cover">
                        <img src={require('../../assets/image/phone-img.png').default} alt="" width='100%'/>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="footer">
              <Container>
              <Col xs={12}>
              <div className="splist">
                <a href="/service" target='_blank' rel="noreferrer">Terms of Service</a>
                <span className="shu"></span>
                <a href="/privacy" target='_blank' rel="noreferrer">Privacy Policy</a>
              </div>
              </Col>
              
              </Container>
              
            </div>
          </div>
          <div className="pendant pendant1">
            <img src={require('../../assets/image/pendant1.png').default} width={'100%'} alt="" />
          </div>
        </div>
      </div>
  );
}

export default Home;