import './index.scss'

const AndroidPrivacyPolicy = (): JSX.Element => {
  return (
    <>
      <div className="Privacy">
        <div className="container-fluid container">
          <h1 className='title'>Privacy Policy</h1>
          <p className='date'>Effective: 28 June, 2022</p>
          <p>R3AL Cam is a 3D face swap camera app that serves as a -based social camera app. Users can enjoy 3D face swapping with lenses from some of the best artist projects in the industry. R3AL Cam offers the best 3D lenses, effects, and filters for everyone to enjoy taking photos and videos. R3AL Cam is simple and easy to use. Choose the lenses menu and tap to record a video or take a photo. You can fully express yourself with 3D lenses, stickers, GIFs, and more!</p>
          <p>When you use these services, you’ll share some information with us. So we want to be upfront about the information we collect, how we use it, whom we share it with, and the controls we give you to access, update, and delete your information. That’s why we’ve written this Privacy Policy. And it’s why we’ve tried to write it in a way that’s easy to understand for all our users and blissfully free of the legalese that often clouds these documents. Of course, if you still have questions about anything in our Privacy Policy, contact us <a href='mailto:feedbackr3al@mail.io' target='_blank' rel="noreferrer">here</a>. You should read our entire Privacy Policy, and be aware of the information we collect, how we use it, whom we share it with, and the controls we give you to access, update and delete your information.</p>
          <h2 className='subtitle'>Information You Provide</h2>
          <p>When you interact with our services, we collect information that you provide to us. We do not store any of this information on our servers or any third-party servers related to R3AL Cam. We will not ask you to set up an account, so we will not need to collect any information. We do this to help you maintain full ownership of your data and information, so we do not collect any of your personal information and data on any of our, or third-party servers. We may also ask you to provide us with some additional information that will be publicly visible on our services, such as a profile picture or avatar and others. We do not provide any messaging services. The same common sense that applies to the internet at large applies to our services as well: Don’t share content that you wouldn’t want someone to save or share. When you contact customer support or communicate with us in any other way, we’ll collect whatever information you volunteer or that we need to resolve your question.</p>
          <h2 className='subtitle'>Information We Get When You Use Our Services.</h2>
          <p>When you use our services, we do not collect any information about which of those services you’ve used and how you’ve used them.</p>
          <p>- Camera and Photos. Many of our services require us to collect images and other information from your device’s camera and photos. For example, you won’t be able to edit R3AL Cam photos and or videos or upload from your camera roll unless we can access your camera or photos and videos.</p>
          <h2 className='subtitle'>How We Use Information</h2>
          <p>What do we do with the information we collect? Provide you with an amazing set of products and services that we relentlessly improve. Here are the ways we do that:</p>
          <p>- When you contact our support team or send us feedback emails according to the information that you authorize us to receive we may develop, operate, improve, deliver, maintain, and protect our products and services.</p>
          <p>- monitor and analyze trends and usage.</p>
          <p>- personalize our services for better service delivery.</p>
          <p>With all R3AL Cam users, our business partners, and the general public. We may share the following information with all R3AL Cam users as well as with our business partners and the general public:</p>
          <p>- public information like your username, your avatar, and copyrights in lenses.</p>
          <p>- With our affiliates. We may share information with entities within the R3AL Cam family of companies.</p>
          <p> When you use R3AL Cam to take photos videos and gifs we may use your facial data to capture facial characters, and over 50 different facial muscle movements, including smiles, frowns, and more and we may animate your expressions.</p>
         <p>-You have the right to stop and cease using R3AL Cam to take photos, videos, and gifs, to animate the faces in our app. Once you stop and cease to use our app, we won't continue to use your facial data.</p>
         <p>-Our privacy policy is not to disclose and share your personal information including your facial data and the data related to facial manipulation features.</p>

          <p>We may share information about you for legal, safety, and security reasons. We may share information about you if we reasonably believe that disclosing the information is needed to:</p>
          <p>- comply with any valid legal process, governmental request, or applicable law, rule, or regulation.</p>
          <p>- investigate, remedy, or enforce potential Terms of Service and Community Guidelines violations.</p>
          <p>- protect the rights, property, or safety of us, our users, or others.</p>
          <p>- detect and resolve any fraud or security concerns.</p>
          <p>- We may share information about you as part of a merger or acquisition. If R3AL Cam gets involved in a merger, asset sale, financing, liquidation or bankruptcy, or acquisition of all or some portion of our business to another company, we may share your information with that company before and after the transaction closes.</p>
          <p>- Non-personal information. We may also share with third parties that provide services to us or perform business purposes for us aggregated, non-personally identifiable or de-identified information.</p>
          <h2 className='subtitle'>Third-Party Content and Integrations</h2>
          <p>Our services may contain third-party content and integrations. Examples include third-party integrations in the Camera and third-party R3AL Cam kit integrations. Through these integrations, you may be providing information to the third party as well as to R3AL Cam. We are not responsible for how those third parties collect or use your information. As always, we encourage you to review the privacy policies of every third-party service that you visit or use, including those third parties you interact with through our services.</p>
          <h2 className='subtitle'>Control Over Your Information</h2>
          <p>We want you to be in control of your information, so we provide you with the following tools.</p>
          <p>- Access, Correction, and Portability. You can access and edit most of your basic account information right in our apps. Because your privacy is important to us, we will ask you to verify your identity or provide additional information before we let you access or update your personal information. We may also reject your request to access or update your personal information for a number of reasons, including, for example, if the request risks the privacy of other users or is unlawful.</p>
          <p>- Revoking permissions. In most cases, if you let us use your information, you can simply revoke your permission by changing the settings in the app or on your device if your device offers those options. Of course, if you do that, certain services may lose full functionality.</p>
          <h2 className='subtitle'>State and Region-Specific Information</h2>
          <p>You may have specific privacy rights in your state or region. For example, in the United States, residents of California and other states have specific privacy rights. R3AL Cam users in the European Economic Area (EEA), the UK, Brazil, the Republic of Korea, and other jurisdictions also have specific rights. We keep an up-to-date overview of state and region-specific disclosures.</p>
          <h2 className='subtitle'>Revisions to the Privacy Policy</h2>
          <p>We may change this Privacy Policy from time to time. But when we do, we’ll let you know one way or another. Sometimes, we’ll let you know by revising the date at the top of the Privacy Policy that’s available on our website and mobile application. Other times, we may provide you with additional notice (such as adding a statement to our websites’ homepages or providing you with an in-app notification).</p>
        </div>
      </div>
    </>
  );
}

export default AndroidPrivacyPolicy;