import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/Home';
// import TermsOfService from './pages/TermsOfService';
// import PrivacyPolicy from './pages/PrivacyPolicy';
import AndroidPrivacyPolicy from './pages/AndroidPrivacyPolicy';
import AndroidTermsOfService from './pages/AndroidTermsOfService';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/service' element={<AndroidTermsOfService/>} />
        <Route path='/privacy' element={<AndroidPrivacyPolicy/>} />


        
      </Routes>
      <ToastContainer theme="dark" autoClose={3000} hideProgressBar />
    </Router>
  );
}

export default App;
